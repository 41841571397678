/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import map from 'lodash/map'
import { useRouter } from 'next/router'
import { Menu } from '@headlessui/react'
import { useCookies } from 'react-cookie'
import React, { useCallback, useState, useEffect } from 'react'

import Logo from '@/components/Logo'
import Button from '@/components/base/Button'
import BaseModal from '@/components/base/Modal'
import Navigation from '@/components/base/Navigation'
import ModifyUser from '@/components/modals/ModifyUser'
import ChangePassword from '@/components/modals/ChangePassword'
import AddExistingTrip from '@/components/modals/AddExistingTrip'
import ModifyMobileNumber from '@/components/modals/ModifyMobileNumber'

import styles from '@/styles/Header.module.css'

import { useSession } from '@/contexts/Session'
import axios from '../../data/axios'
import useFetcher from '@/redux/hooks/useFetcher'

const Header = () => {
    const { push } = useRouter()
    const [profile, setProfile] = useCookies(['profile'])

    const { setAlertMessage } = useFetcher()
    const {
        user,
        authToken,
        setAuthToken,
        clearNextURL,
        removeAuthToken,
        scopes,
        setPrimaryClient,
        primaryClient,
        removePrimaryClient,
    } = useSession()

    const selectedPrimaryClient = primaryClient.clientDetails
        ? JSON.parse(JSON.stringify(primaryClient.clientDetails))
        : null

    const [primaryClientList, setPrimaryClientList] = useState(null)

    const [name, setName] = useState(null)
    const [initials, setInitials] = useState(null)
    const [openModal, setOpenModal] = useState(false)
    const [changePassword, setChangePassword] = useState(false)
    const [addExistingTrip, setAddExistingTrip] = useState(false)
    const [openModifyMobileNumberModal, setModifyMobileNumberModal] =
        useState(false)

    const navigation = [
        {
            label: 'HOME',
            route: '/home',
        },
        {
            label: 'BOOK A FLIGHT',
            route: '/book-flight',
            childRoutes: [
                {
                    route: '/book-flight/confirm-itinerary',
                },
            ],
        },
        {
            label: 'MY TRIPS',
            route: '/my-trip',
            childRoutes: [
                {
                    route: '/my-trip/[id]/review-quotes',
                },
                {
                    route: '/my-trip/[id]/review-contract',
                },
                {
                    route: '/my-trip/[id]/payment',
                },
                {
                    route: '/my-trip/[id]/flight-details',
                },
            ],
        },
        {
            label: 'Manage Frequent Fliers',
            route: '/frequent-fliers',
        },
        {
            label: 'Manage Sub Accounts',
            route: '/sub-accounts',
        },
        {
            label: 'Add An Existing Trip',
            click: true,
        },
        {
            label: 'Manage Credit Cards',
            route: '/payment-methods',
        },
        {
            label: 'Meet The Fleet',
            route: '/meet-the-fleet',
        },
        {
            label: 'Contact Us',
            route: '/contact-us',
        },
        {
            label: 'Privacy Policy',
            route: '/privacy-policy',
        },
    ].map((navItem) => {
        if (
            navItem.label === 'Manage Sub Accounts' &&
            !scopes?.includes('primary-client')
        ) {
            navItem.label = 'View Sub Accounts'
        }

        if (
            navItem.label === 'Add An Existing Trip' &&
            !scopes?.includes('primary-client') &&
            !scopes?.includes('premium-associate-client')
        ) {
            navItem.click = false
        }

        return {
            ...navItem,
            disabled: !scopes.includes(
                navItem?.route?.slice(1)?.replace('/', '-')
            ),
        }
    })

    useEffect(() => {
        if (user) {
            const fullName = `${user?.firstName} ${user?.lastName}`
            setName(fullName)
            if (user?.roleName !== 'Client' && !selectedPrimaryClient) {
                setPrimaryClient(
                    'clientDetails',
                    JSON.stringify({
                        firstName: user?.clientFirstName,
                        lastName: user?.clientLastName,
                        clientId: user?.clientId,
                    })
                )
            }
            setChangePassword(!!user.forceChangePassword)

            if (
                !profile.profile &&
                !user.forceChangePassword &&
                !user?.mobileNumber
            ) {
                setModifyMobileNumberModal(true)
            }
        }
    }, [
        user,
        profile,
        setName,
        setPrimaryClient,
        selectedPrimaryClient,
        setModifyMobileNumberModal,
    ])

    const fetchData = useCallback(async () => {
        try {
            const { data } = await axios.get(`sub-account/me?type=client`)

            setPrimaryClientList(data?.data || [])
        } catch ({ response }) {
            setPrimaryClientList([])
        }
    }, [])

    const switchClient = useCallback(
        async (item) => {
            try {
                const { data } = await axios.put(`user/switch-client/me`, {
                    clientId: item.clientId,
                })

                setAuthToken('accessToken', data.data.accessToken, {
                    path: '/',
                })

                setAuthToken('refreshToken', data.data.refreshToken, {
                    path: '/',
                })

                push('/')

                setPrimaryClient(
                    'clientDetails',
                    JSON.stringify({
                        firstName: item?.clientFirstName,
                        lastName: item?.clientLastName,
                        clientId: item?.clientId,
                    })
                )
            } catch ({ response }) {
                setPrimaryClientList([])
            }
        },
        [push, setAuthToken, setPrimaryClient]
    )

    useEffect(() => {
        if (user) {
            fetchData()
        }
    }, [fetchData, user])

    useEffect(() => {
        if (name) {
            const fullName = name.split(' ')
            let fullNameInitials = fullName[0].substring(0, 1).toUpperCase()
            if (fullName.length > 1) {
                fullNameInitials += fullName[fullName.length - 1]
                    .substring(0, 1)
                    .toUpperCase()
            }
            setInitials(fullNameInitials)
        }
    }, [name, setInitials])

    const signOut = useCallback(async () => {
        const signInUrl = `${process.env.NEXT_PUBLIC_API_URL}/auth/logout`

        const fetchOptions = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken?.accessToken}`,
            },
        }

        await fetch(signInUrl, fetchOptions)
        if (user?.roleName !== 'Client') {
            removePrimaryClient('clientDetails')
        }
        removeAuthToken('accessToken', {
            path: '/',
            sameSite: true,
        })

        clearNextURL('path')

        setAlertMessage(null)
    }, [
        authToken?.accessToken,
        clearNextURL,
        removeAuthToken,
        removePrimaryClient,
        setAlertMessage,
        user?.roleName,
    ])

    return (
        <div className="sticky-top-header ipad:m-0 ipad:w-full">
            <div className="inner">
                <div className="m-auto flex w-full max-w-[1024px]">
                    <Logo width={44} height={42} link="/home" />
                    <div className="ml-auto mobile:absolute mobile:top-[56px]">
                        <Navigation
                            mainMenu
                            items={navigation}
                            navClick={() => {
                                setAddExistingTrip(true)
                            }}
                        />

                        <div className={styles.userProfileContainer}>
                            <div className={styles.userProfile}>
                                <Menu
                                    as="div"
                                    className="relative inline-block"
                                >
                                    <Menu.Button className="dropdown-button items-center">
                                        <div className={styles.avatar}>
                                            <p className="mb-0">{initials}</p>
                                        </div>
                                        <div className={styles.userInfo}>
                                            <p>{name}</p>
                                            {!scopes.includes(
                                                'primary-client'
                                            ) && (
                                                <p>
                                                    (
                                                    {[
                                                        selectedPrimaryClient?.firstName,
                                                        selectedPrimaryClient?.lastName,
                                                    ]
                                                        .filter((a) => a)
                                                        .join(' ') || 'N/A'}
                                                    )
                                                </p>
                                            )}
                                        </div>
                                        <i className="icon-caret-down ml-1 text-xs"></i>
                                    </Menu.Button>
                                    <Menu.Items className="dropdown-menu -right[15px] top-[37px] flex w-[140px] flex-col overflow-visible">
                                        <Menu.Item>
                                            <Button
                                                variant="link"
                                                type="button"
                                                text="Update Profile"
                                                size="link-xs"
                                                modification="btn-header-profile"
                                                click={() => {
                                                    setOpenModal(true)
                                                }}
                                            />
                                        </Menu.Item>
                                        <Menu.Item>
                                            <Button
                                                variant="link"
                                                type="button"
                                                text="Change Password"
                                                size="link-xs"
                                                modification="btn-header-profile"
                                                click={() => {
                                                    setChangePassword(true)
                                                }}
                                            />
                                        </Menu.Item>
                                        {!scopes.includes('primary-client') && (
                                            <Menu.Item>
                                                <div className="">
                                                    <Menu
                                                        as="div"
                                                        className="relative inline-block"
                                                    >
                                                        <Menu.Button className="dropdown-button items-center overflow-visible py-0">
                                                            <Button
                                                                variant="link"
                                                                icon="caret-right"
                                                                position="right"
                                                                iconStyle="pl-3 text-[11px] text-common-black"
                                                                type="button"
                                                                text="Switch Client"
                                                                size="link-xs"
                                                                modification="btn-header-profile"
                                                            />
                                                        </Menu.Button>
                                                        <Menu.Items className="dropdown-menu sub-dropdown-menu top-0 flex w-[140px] flex-col overflow-visible">
                                                            {map(
                                                                primaryClientList,
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <Menu.Item>
                                                                        <Button
                                                                            variant="link"
                                                                            type="button"
                                                                            disabled={
                                                                                selectedPrimaryClient.clientId ===
                                                                                item.clientId
                                                                            }
                                                                            text={
                                                                                [
                                                                                    item?.clientFirstName,
                                                                                    item?.clientLastName,
                                                                                ]
                                                                                    .filter(
                                                                                        (
                                                                                            a
                                                                                        ) =>
                                                                                            a
                                                                                    )
                                                                                    .join(
                                                                                        ' '
                                                                                    ) ||
                                                                                'N/A'
                                                                            }
                                                                            size="link-xs"
                                                                            modification="btn-header-profile"
                                                                            click={() => {
                                                                                switchClient(
                                                                                    item
                                                                                )
                                                                            }}
                                                                        />
                                                                    </Menu.Item>
                                                                )
                                                            )}
                                                        </Menu.Items>
                                                    </Menu>
                                                </div>
                                            </Menu.Item>
                                        )}
                                        <Menu.Item>
                                            <Button
                                                variant="link"
                                                type="button"
                                                text="Sign out"
                                                size="link-xs"
                                                modification="btn-header-profile"
                                                click={() => signOut()}
                                            />
                                        </Menu.Item>
                                    </Menu.Items>
                                </Menu>
                            </div>
                        </div>
                    </div>
                </div>
                <BaseModal
                    ModalBody={ChangePassword}
                    isModalOpen={changePassword}
                    isModalClose={() => setChangePassword(false)}
                    modification="w-[348px]"
                    title={
                        user?.forceChangePassword
                            ? 'Change your password'
                            : 'Change Password'
                    }
                    size="xs"
                    props={{ setChangePassword }}
                />
                <BaseModal
                    ModalBody={ModifyUser}
                    isModalOpen={openModal}
                    isModalClose={() => setOpenModal(false)}
                    title="Update Profile"
                    props={{ setOpenModal }}
                />
                <BaseModal
                    ModalBody={ModifyMobileNumber}
                    isModalOpen={openModifyMobileNumberModal}
                    isModalClose={() => setModifyMobileNumberModal(false)}
                    title="Welcome!"
                    size="sm"
                    props={{ setProfile, setModifyMobileNumberModal }}
                />

                <BaseModal
                    ModalBody={AddExistingTrip}
                    isModalOpen={addExistingTrip}
                    isModalClose={() => {
                        setAddExistingTrip(false)
                    }}
                    size="sm"
                    title={'Add an Existing Trip'}
                    props={{
                        setOpenModal: setAddExistingTrip,
                    }}
                />
            </div>
        </div>
    )
}

export default Header
