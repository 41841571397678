import keys from 'lodash/keys'
import { Formik, Form } from 'formik'
import { useMemo, useState, useCallback, useEffect } from 'react'

import Alert from '@/components/base/Alert'
import Button from '@/components/base/Button'
import PhoneNumberField from '@/components/base/PhoneNumberField'

import styles from '../../styles/Input.module.css'

import axios from '@/data/axios'
import { useSession } from '@/contexts/Session'
import modelValidation from '@/validations/register'

const ModifyUser = ({ setProfile, setModifyMobileNumberModal }) => {
    const { user, setUser } = useSession()

    const [loading, setLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState(null)

    const initialState = useMemo(
        () => ({
            id: user?.id,
            email: user?.email,
            streetAddress1: user?.streetAddress1 || '',
            streetAddress2: user?.streetAddress2 || '',
            city: user?.city || '',
            state: user?.state || '',
            zipCode: user?.zipCode || '',
            lastName: user?.lastName,
            firstName: user?.firstName,
            mobileNumber: user?.mobileNumber,
            workMobileNumber: user?.workMobileNumber || '',
            isCompany: !user?.firstName && user?.lastName ? true : false,
            isModifyMobileNumberOnly: true,
        }),
        [user]
    )

    const handleSubmit = useCallback(
        async (formData, { setErrors, setSubmitting }) => {
            setSubmitting(false)
            setLoading(true)
            try {
                const { data } = await axios.put(`user/me`, formData)
                if (data) {
                    const { message } = data
                    await setUser({
                        ...user,
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        streetAddress1: formData?.streetAddress1,
                        streetAddress2: formData?.streetAddress2,
                        city: formData?.city,
                        state: formData?.state,
                        zipCode: formData?.zipCode,
                        mobileNumber: formData.mobileNumber,
                        workMobileNumber: formData.workMobileNumber,
                    })
                    setSuccessMessage(message)
                }
            } catch ({ response }) {
                setLoading(false)
                let message = response.data.message
                let key =
                    message === 'Email already exists.'
                        ? 'email'
                        : keys(initialState)[0]
                if (response.data.errors) {
                    const entries = Object.entries(response.data.errors)
                    if (entries.length) {
                        key = entries[0][0]
                        message = entries[0][1]
                    }
                }
                setErrors({
                    [key]: message,
                })
            }
        },
        [initialState, setSuccessMessage, setUser, user]
    )

    const handleReset = useCallback(async () => {
        setProfile('profile', true, {
            path: '/',
        })
        setModifyMobileNumberModal(false)
    }, [setProfile, setModifyMobileNumberModal])

    useEffect(() => {
        if (successMessage) {
            const timer = setTimeout(() => {
                setSuccessMessage(null)
                setLoading(false)
                setModifyMobileNumberModal(false)
            }, 2000)
            return () => clearTimeout(timer)
        }
    }, [
        successMessage,
        setSuccessMessage,
        setLoading,
        setModifyMobileNumberModal,
    ])

    return (
        <>
            <div>
                <Formik
                    onReset={handleReset}
                    onSubmit={handleSubmit}
                    initialValues={initialState}
                    validationSchema={modelValidation}
                    validateOnBlur={false}
                >
                    {({ values, errors, touched }) => {
                        const firstKey = keys(errors)[0]
                        const showError =
                            !!errors[firstKey] && !!touched[firstKey]

                        return (
                            <Form noValidate>
                                <p className="">
                                    We don't have a phone number for you. Having
                                    a phone number is essential for account
                                    recovery or verifying your identity when you
                                    log in from a new computer or mobile device.
                                </p>
                                <div>
                                    <div className="grid gap-4 gap-y-0">
                                        <div className={styles.phoneInput}>
                                            <PhoneNumberField
                                                id="mobileNumber"
                                                name="mobileNumber"
                                                label="Enter your phone number below:"
                                                placeholder="+1 (XXX) XXX-XXXX "
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-2 mt-2">
                                    {showError && (
                                        <Alert
                                            variant="danger"
                                            message={errors[firstKey]}
                                            icon="close"
                                            iconStyle="text-danger-500 pr-2 text-xs"
                                        ></Alert>
                                    )}

                                    {successMessage && (
                                        <Alert
                                            variant="success"
                                            message={successMessage}
                                            icon="check"
                                            iconStyle="text-success-500 pr-2 text-xs"
                                        ></Alert>
                                    )}
                                </div>
                                <div className="col-span-2 grid grid-cols-2 gap-3">
                                    <div>
                                        <Button
                                            text="Skip"
                                            type="reset"
                                            variant="outline_interface"
                                            modification="w-full"
                                            size="other"
                                            click={() =>
                                                setModifyMobileNumberModal(
                                                    false
                                                )
                                            }
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            id="update_profile_submit"
                                            modification="w-full shadow-md"
                                            variant="primary"
                                            text="Update"
                                            type="submit"
                                            size="other"
                                            loading={loading}
                                        />
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </>
    )
}

export default ModifyUser
