import * as Yup from 'yup'
import { register } from './messages'

export default Yup.object().shape({
    firstName: Yup.string()
        .nullable()
        .when('isCompany', {
            is: false,
            then: Yup.string()
                .required(register.firstName.required)
                .min(2, register.firstName.min)
                .max(100, register.firstName.max),
        }),
    lastName: Yup.string()
        .when('isCompany', {
            is: false,
            then: Yup.string()
                .required(register.lastName.required)
                .min(2, register.lastName.min)
                .max(100, register.lastName.max),
        })
        .when('isCompany', {
            is: true,
            then: Yup.string()
                .required(register.companyName.required)
                .min(2, register.companyName.min)
                .max(100, register.companyName.max),
        }),

    email: Yup.string()
        .email(register.email.invalid)
        .required(register.email.required),

    mobileNumber: Yup.string()
        .nullable()
        .required(register.mobileNumber.required)
        .matches(/^[+0-9-() ]{12,17}$/, register.mobileNumber.matches),

    workMobileNumber: Yup.string()
        .nullable()
        .when(['id', 'isModifyMobileNumberOnly'], {
            is: (value, isModifyMobileNumberOnly) =>
                !isModifyMobileNumberOnly && value,
            then: Yup.string().matches(
                /^[+0-9-() ]{12,17}$/,
                register.workMobileNumber.matches
            ),
        }),

    streetAddress1: Yup.string()
        .nullable()
        .when(['id', 'isModifyMobileNumberOnly'], {
            is: (value, isModifyMobileNumberOnly) =>
                !isModifyMobileNumberOnly && value,
            then: Yup.string()
                .required(register.streetAddress1.required)
                .min(2, register.streetAddress1.min)
                .max(255, register.streetAddress1.max),
        }),

    streetAddress2: Yup.string()
        .nullable()
        .when('id', {
            is: (value) => value,
            then: Yup.string()
                .min(2, register.streetAddress2.min)
                .max(255, register.streetAddress2.max),
        }),

    city: Yup.string()
        .nullable()
        .when(['id', 'isModifyMobileNumberOnly'], {
            is: (value, isModifyMobileNumberOnly) =>
                !isModifyMobileNumberOnly && value,
            then: Yup.string()
                .required(register.city.required)
                .min(2, register.city.min)
                .max(100, register.city.max),
        }),

    state: Yup.string()
        .nullable()
        .when(['id', 'isModifyMobileNumberOnly'], {
            is: (value, isModifyMobileNumberOnly) =>
                !isModifyMobileNumberOnly && value,
            then: Yup.string()
                .required(register.state.required)
                .min(2, register.state.min)
                .max(100, register.state.max),
        }),

    zipCode: Yup.string()
        .nullable()
        .when(['id', 'isModifyMobileNumberOnly'], {
            is: (value, isModifyMobileNumberOnly) =>
                !isModifyMobileNumberOnly && value,
            then: Yup.string()
                .required(register.zipCode.required)
                .min(2, register.zipCode.min)
                .max(10, register.zipCode.max),
        }),

    password: Yup.string()
        .nullable()
        .when('id', {
            is: undefined,
            then: Yup.string()
                .required(register.password.required)
                .min(8, register.password.min)
                .max(50, register.password.max),
        }),

    confirmPassword: Yup.string().oneOf(
        [Yup.ref('password'), null],
        register.password.match
    ),

    referredBy: Yup.string().nullable(),
})
